// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dubai-property-services-buy-property-in-dubai-new-projects-in-dubai-index-js": () => import("./../../../src/pages/dubai-property-services/buy-property-in-dubai/new-projects-in-dubai/index.js" /* webpackChunkName: "component---src-pages-dubai-property-services-buy-property-in-dubai-new-projects-in-dubai-index-js" */),
  "component---src-pages-pre-qualified-confirmation-index-js": () => import("./../../../src/pages/pre-qualified-confirmation/index.js" /* webpackChunkName: "component---src-pages-pre-qualified-confirmation-index-js" */),
  "component---src-pages-pre-qualified-index-js": () => import("./../../../src/pages/pre-qualified/index.js" /* webpackChunkName: "component---src-pages-pre-qualified-index-js" */),
  "component---src-pages-properties-commercial-for-rent-index-js": () => import("./../../../src/pages/properties/commercial/for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-commercial-for-rent-index-js" */),
  "component---src-pages-properties-commercial-for-sale-index-js": () => import("./../../../src/pages/properties/commercial/for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-commercial-for-sale-index-js" */),
  "component---src-pages-properties-for-rent-index-js": () => import("./../../../src/pages/properties/for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-for-rent-index-js" */),
  "component---src-pages-properties-for-sale-index-js": () => import("./../../../src/pages/properties/for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-for-sale-index-js" */),
  "component---src-pages-properties-let-index-js": () => import("./../../../src/pages/properties/let/index.js" /* webpackChunkName: "component---src-pages-properties-let-index-js" */),
  "component---src-pages-properties-map-commercial-for-rent-index-js": () => import("./../../../src/pages/properties-map/commercial/for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-map-commercial-for-rent-index-js" */),
  "component---src-pages-properties-map-commercial-for-sale-index-js": () => import("./../../../src/pages/properties-map/commercial/for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-map-commercial-for-sale-index-js" */),
  "component---src-pages-properties-map-for-rent-index-js": () => import("./../../../src/pages/properties-map/for-rent/index.js" /* webpackChunkName: "component---src-pages-properties-map-for-rent-index-js" */),
  "component---src-pages-properties-map-for-sale-index-js": () => import("./../../../src/pages/properties-map/for-sale/index.js" /* webpackChunkName: "component---src-pages-properties-map-for-sale-index-js" */),
  "component---src-pages-properties-map-let-index-js": () => import("./../../../src/pages/properties-map/let/index.js" /* webpackChunkName: "component---src-pages-properties-map-let-index-js" */),
  "component---src-pages-properties-map-sold-index-js": () => import("./../../../src/pages/properties-map/sold/index.js" /* webpackChunkName: "component---src-pages-properties-map-sold-index-js" */),
  "component---src-pages-properties-sold-index-js": () => import("./../../../src/pages/properties/sold/index.js" /* webpackChunkName: "component---src-pages-properties-sold-index-js" */),
  "component---src-pages-property-search-index-js": () => import("./../../../src/pages/property-search/index.js" /* webpackChunkName: "component---src-pages-property-search-index-js" */),
  "component---src-pages-qatar-property-services-buy-property-view-for-our-latest-projects-index-js": () => import("./../../../src/pages/qatar-property-services/buy-property/view-for-our-latest-projects/index.js" /* webpackChunkName: "component---src-pages-qatar-property-services-buy-property-view-for-our-latest-projects-index-js" */),
  "component---src-pages-qatar-property-services-rent-a-property-view-for-our-latest-projects-rent-index-js": () => import("./../../../src/pages/qatar-property-services/rent-a-property/view-for-our-latest-projects-rent/index.js" /* webpackChunkName: "component---src-pages-qatar-property-services-rent-a-property-view-for-our-latest-projects-rent-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-careers-details-template-js": () => import("./../../../src/templates/careers-details-template.js" /* webpackChunkName: "component---src-templates-careers-details-template-js" */),
  "component---src-templates-community-template-js": () => import("./../../../src/templates/community-template.js" /* webpackChunkName: "component---src-templates-community-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-insights-template-js": () => import("./../../../src/templates/insights-template.js" /* webpackChunkName: "component---src-templates-insights-template-js" */),
  "component---src-templates-interior-details-js": () => import("./../../../src/templates/interior-details.js" /* webpackChunkName: "component---src-templates-interior-details-js" */),
  "component---src-templates-new-project-template-js": () => import("./../../../src/templates/new-project-template.js" /* webpackChunkName: "component---src-templates-new-project-template-js" */),
  "component---src-templates-peoples-template-js": () => import("./../../../src/templates/peoples-template.js" /* webpackChunkName: "component---src-templates-peoples-template-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */)
}

